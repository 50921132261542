@charset "UTF-8";
/*!
 =========================================================
 * ArchitectUI ReactJS Dashboard Theme - v1.0.0
 =========================================================
 * Product Page: https://dashboardpack.com
 * Copyright 2019 DashboardPack (https://dashboardpack.com)
 * Licensed under MIT (https://github.com/DashboardPack/architectui-react-theme-free/blob/master/LICENSE)
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: #495057;
    background-color: #fff;
}

a {
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    text-decoration: underline;
}
