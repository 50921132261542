.appear {
  opacity: 0;
  transform: translateY(20px);
}

.appearActive {
  opacity: 1;
  transform: translateY(0);
  transition: transform 1000ms, opacity 1000ms;
}
